.sheet {
  background: white;
  box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
  margin: 5mm auto;

}

.sheet {
  width: 153px;
  height: 237px;
  margin: 20px auto;
}

.sheet {

  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.top_img {
  width: 153px;
  height: 42px;
}

.top_img>img {
  width: 100%;
  height: 100%;
}

.frame {
  width: 153px;
  height: 85px;
  position: relative;
}

.frame_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 153px;
  height: 85px;
}

img {
  vertical-align: middle;
}

.user_image {
  padding: 14px 0 0 47px;
  /* width: 61px;
  height: 63px; */
}

.user_image img {
  width: 61px;
  height: 63px;
}

.user_details {
  text-align: center;
  width: 100%;
  padding: 5px 6px;
}

.user_details h1 {
  color: #0077ac;
  font-size: 9px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.user_details h2 {
  color: #334355;
  font-size: 9px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.user_details p {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 8px;
  font-weight: bold;
  line-height: 9px;
}

.footer {
  width: 153px;
  height: 4px;
}