@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }
  
    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
  
    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }
  
    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }
  
    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
  
    table td:last-child {
        border-bottom: 0;
    }
}


/* code=><table className="DataTableGrid display responsive dataTable" style={{ width: '100%' }}>
<thead>
    <tr>
        <th>S.No</th>
        <th>Date</th>
        <th>Customer Number</th>
        <th>Operator</th>
        <th>Amount</th>
        <th>Status</th>
        <th>Action</th>
        <th>Enquiry</th>
    </tr>
</thead>
<tbody>
    {data && data?.map((item, i) => {
        return <tr key={item?._id}>
            <td data-label="S.No" className="px-2">{(i + 1) + (page * count)}</td>
            <td data-label="Date">{item?.createdAt}</td>
            <td data-label="Customer Number">{item?.ca_num}</td>
            <td data-label="Operator">{item?.operator_name}</td>
            <td data-label="Amount">{item?.amount}</td>
            <td data-label="Status">
                {item?.status === 2 && <button type="button" className="btn btn-success">Success</button>}
                {item?.status === 1 && <button type="button" className="btn btn-warning">Pending</button>}
                {item?.status === 3 && <button type="button" className="btn btn-danger">Failed</button>}
            </td>
            <td data-label="Action">
                <FaEye
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewInvoice(item?._id)}
                />
            </td>
            <td data-label="Enquiry">
                <Button
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleInquiry(item._id)}
                    disabled={loading2}
                >
                    Enquiry
                </Button>
            </td>
        </tr>
    })}
</tbody>
</table> */