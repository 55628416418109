/* AepsPayoutModal.css */

.aeps-payout-modal .modal-content {
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.aeps-payout-modal .modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.aeps-payout-modal .modal-title {
  color: #495057;
  font-weight: 600;
}

.aeps-payout-modal .modal-body {
  padding: 20px;
}

.aeps-payout-modal .close {
  font-size: 1.5rem;
  font-weight: 700;
  color: #6c757d;
  opacity: 0.5;
  transition: opacity 0.15s;
}

.aeps-payout-modal .close:hover {
  opacity: 1;
}

@media (max-width: 576px) {
  .aeps-payout-modal .modal-dialog {
    margin: 0.5rem;
  }
}