.card-flip {
  perspective: 1000px;
  height: 300px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-flip:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

.bg-primary {
  background-color: #1a237e !important;
}

.text-primary {
  color: #1a237e !important;
}

.btn-primary {
  background-color: #1a237e;
  border-color: #1a237e;
}

.btn-primary:hover {
  background-color: #0e1442;
  border-color: #0e1442;
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.package-icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  /* width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #1a237e; */
}


.card-flip:hover .package-icon {
  transform: scale(1.1);
}
.expiredatepackage{
  color: #ffffff;
}