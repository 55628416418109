.calentim-container,
.calentim-container-mobile {
    font-size: 15px;
    float: left;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 999999;
    -webkit-font-smoothing: antialiased;
    font-kerning: normal;
    -webkit-font-kerning: normal;
    -webkit-text-size-adjust: 100%
}

.calentim-container:focus,
.calentim-container-mobile:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none
}

.calentim-container .calentim-input,
.calentim-container-mobile .calentim-input {
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    overflow: hidden;
    top: inherit;
    background: white;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.4);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.calentim-container .calentim-input.calentim-input-vertical-range,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-left,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-left {
    border-right: 1px solid #ddd
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-right,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-right {
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges {
    border-top: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    padding-bottom: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges .calentim-range-header-container,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges .calentim-range-header-container {
    padding: 8px 12px
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges .calentim-range,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges .calentim-range {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    padding: 8px 12px;
    margin: 3px 6px;
    background: #f8f8ff;
    border: 1px solid #ddd;
    border-radius: 3px
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges .calentim-range.calentim-range-selected,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-right .calentim-ranges .calentim-range.calentim-range-selected {
    background: #1885FC;
    color: white
}

.calentim-container .calentim-input.calentim-input-vertical-range .calentim-right .calentim-footer,
.calentim-container-mobile .calentim-input.calentim-input-vertical-range .calentim-right .calentim-footer {
    border-top: 1px solid #ddd;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0
}

.calentim-container .calentim-input .calentim-header,
.calentim-container-mobile .calentim-input .calentim-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid #ddd
}

.calentim-container .calentim-input .calentim-header .calentim-header-separator,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-separator {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    display: block;
    margin: 0;
    margin-top: -2px;
    padding: 0 16px;
    color: aliceblue;
    font-size: 32px
}

.calentim-container .calentim-input .calentim-header .calentim-header-start,
.calentim-container .calentim-input .calentim-header .calentim-header-end,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    white-space: nowrap;
    display: block;
    margin-right: 10px;
    text-align: left
}

.calentim-container .calentim-input .calentim-header .calentim-header-start .calentim-header-start-day,
.calentim-container .calentim-input .calentim-header .calentim-header-start .calentim-header-end-day,
.calentim-container .calentim-input .calentim-header .calentim-header-end .calentim-header-start-day,
.calentim-container .calentim-input .calentim-header .calentim-header-end .calentim-header-end-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-end-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-start-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-day {
    float: left;
    font-size: 2.5em;
    color: #1885FC;
    margin-top: -10px;
    margin-right: 8px;
    font-weight: 100;
    text-align: left
}

.calentim-container .calentim-input .calentim-header .calentim-header-start .calentim-header-start-date,
.calentim-container .calentim-input .calentim-header .calentim-header-start .calentim-header-end-date,
.calentim-container .calentim-input .calentim-header .calentim-header-end .calentim-header-start-date,
.calentim-container .calentim-input .calentim-header .calentim-header-end .calentim-header-end-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-end-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-start-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-date {
    font-size: 0.9em;
    color: #A6AAB3;
    display: inline-block
}

.calentim-container .calentim-input .calentim-header .calentim-header-start .calentim-header-start-weekday,
.calentim-container .calentim-input .calentim-header .calentim-header-start .calentim-header-end-weekday,
.calentim-container .calentim-input .calentim-header .calentim-header-end .calentim-header-start-weekday,
.calentim-container .calentim-input .calentim-header .calentim-header-end .calentim-header-end-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-end-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-start-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-weekday {
    font-size: 0.7em;
    color: #c9c9d1
}

.calentim-container .calentim-input .calentim-calendars,
.calentim-container-mobile .calentim-input .calentim-calendars {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.calentim-container .calentim-input .calentim-calendars .calentim-month-selector,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector {
    position: absolute;
    display: none;
    border: 0px solid #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100
}

.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month {
    background: #eee;
    height: 33.33%;
    width: 25%;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    outline: 1px solid #ddd;
    word-break: break-word;
    word-wrap: break-word;
    text-transform: capitalize
}

.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month.current,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month.current {
    background: #0666CD;
    color: white
}

.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year,
.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next,
.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev {
    margin: 0px;
    background: #eee;
    height: 33.33%;
    width: 20%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 1px solid #ddd;
    text-transform: uppercase;
    word-wrap: break-word;
    word-break: break-all
}

.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year.current,
.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next.current,
.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev.current,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year.current,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next.current,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next.current,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev.current {
    background: #0666CD;
    color: white
}

.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next,
.calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next,
.calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev {
    font-size: 2.65em;
    background: white;
    color: #1885FC
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    float: left;
    padding-bottom: 8px;
    border-right: 1px solid #ddd;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar.no-border-right,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.no-border-right {
    border-right: none
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-dayofweek,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-day,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-disabled,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-weeknumber,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-dayofweek,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-day,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-weeknumber {
    width: calc(100% / 8)
}

@media all and (-ms-high-contrast: none) {

    .calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-dayofweek,
    .calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-day,
    .calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-disabled,
    .calentim-container .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-weeknumber,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-dayofweek,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-day,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-disabled,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar.calentim-calendar-weeknumbers .calentim-days-container .calentim-weeknumber {
        width: calc((100% / 8) - 1px)
    }
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title {
    text-align: center;
    font-weight: 300;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    color: #424242;
    text-transform: capitalize;
    border-bottom: 1px solid #ddd;
    padding: 6px 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 1.2em
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next {
    font-size: 1em;
    cursor: hand;
    cursor: pointer
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev i,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next i,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev i,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next i {
    cursor: pointer
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev {
    float: left
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next {
    float: right
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber {
    width: calc(100% / 7);
    text-align: center;
    padding: 3px;
    font-size: .76em;
    font-weight: 400;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 1px
}

@media all and (-ms-high-contrast: none) {

    .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek,
    .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day,
    .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled,
    .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled,
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber {
        width: calc((100% / 7) - 1px)
    }
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek span,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day span,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled span,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber span {
    pointer-events: none;
    display: block;
    padding: 3px 3px 3px 4px;
    border-radius: 4px
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek {
    text-transform: uppercase;
    font-size: 0.6em;
    font-weight: 600;
    color: #616161
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weeknumber {
    background-color: #f8f8ff;
    color: #568EA3;
    border-right: 1px solid #ddd
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day {
    cursor: pointer;
    overflow: hidden;
    color: #212121;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .1s ease-out;
    -o-transition: background-color .1s ease-out;
    transition: background-color .1s ease-out
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-start,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-end,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-start,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-end {
    background: #1783FF;
    color: white
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-disabled,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-start.calentim-disabled,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-end.calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-start.calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-end.calentim-disabled {
    background: #c6dbf0;
    color: #fff;
    opacity: .5
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-disabled span,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-start.calentim-disabled span,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-end.calentim-disabled span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-disabled span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-start.calentim-disabled span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-end.calentim-disabled span {
    background: transparent !important
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-start,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-start {
    border-radius: 6px 0 0 6px
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-start span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-start span {
    background: #0666CD;
    border-radius: 4px
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-end,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-end {
    border-radius: 0 6px 6px 0
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-end span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-end span {
    background: #0666CD;
    border-radius: 4px
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-start.calentim-end,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-selected.calentim-start.calentim-end {
    border-radius: 6px !important
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected),
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected) {
    background-color: #c8e1ff;
    color: black
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected) span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected) span {
    background-color: #c8e1ff
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected).calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected).calentim-disabled {
    opacity: .66
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected).calentim-hovered-first,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected).calentim-hovered-first {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected).calentim-hovered-last,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-hovered:not(.calentim-selected).calentim-hovered-last {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-today,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-today {
    color: #0666CD;
    font-weight: bold
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-today.calentim-hovered,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-today.calentim-hovered {
    color: black
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-today.calentim-selected,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day.calentim-today.calentim-selected {
    color: white
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled.calentim-disabled-range,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled.calentim-disabled-range {
    color: #b8b8df
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled {
    background: #f4f4fa;
    color: #b8b8df
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weekend,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-weekend {
    color: #568EA3
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-next,
.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-prev {
    cursor: pointer;
    font-size: 1.25em
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-next,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-next {
    float: right
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-prev,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-prev {
    float: left
}

.calentim-container .calentim-input .calentim-calendars .calentim-calendar:last-child,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar:last-child {
    border-right: none
}

.calentim-container .calentim-input .calentim-timepickers,
.calentim-container-mobile .calentim-input .calentim-timepickers {
    border-top: 1px solid #ddd;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    background: ghostwhite
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    border-right: 1px solid #ddd
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker:last-child,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker:last-child {
    border-right: none
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-hour-minute-seperator,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-hour-minute-seperator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #1885FC;
    font-size: 1.8em;
    font-weight: bold;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 .1em;
    margin-top: -2px
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 5px;
    font-size: 0.7em;
    font-weight: bold;
    color: #6a6e71
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-am,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-am {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    background: white;
    border-bottom: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 5px
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-pm,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-pm {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 0 0 4px 4px;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 5px
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-ampm-selected,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-ampm-selected {
    background: #0666CD;
    border-color: #0666CD;
    color: white
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    padding: 0;
    margin: 0;
    line-height: 1.4em;
    height: 4.2em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ddd;
    border-radius: 4px 0 0 4px;
    border: 1px solid #ddd;
    border-right: none;
    text-align: center;
    background: white
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected-prev,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected-prev {
    -webkit-transform: perspective(4em) rotateX(30deg);
    transform: perspective(4em) rotateX(30deg);
    color: #424242;
    opacity: .5;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected {
    color: #424242;
    font-size: 1.5em;
    font-weight: bold;
    background: #fff
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected-next,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected-next {
    -webkit-transform: perspective(4em) rotateX(-30deg);
    transform: perspective(4em) rotateX(-30deg);
    color: #424242;
    opacity: .5;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    line-height: 1.4em;
    height: 4.2em;
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ddd;
    background: white;
    border-radius: 4px 0 0 4px;
    border: 1px solid #ddd;
    border-right: none;
    text-align: center
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected-prev,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected-prev {
    -webkit-transform: perspective(4em) rotateX(30deg);
    transform: perspective(4em) rotateX(30deg);
    color: #424242;
    opacity: .5;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected {
    color: #424242;
    font-size: 1.5em;
    font-weight: bold;
    background: #fff;
    -webkit-box-shadow: inset 0 0 10px 100px white;
    box-shadow: inset 0 0 10px 100px white
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected-next,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected-next {
    -webkit-transform: perspective(4em) rotateX(-30deg);
    transform: perspective(4em) rotateX(-30deg);
    color: #424242;
    opacity: .5;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-hours-up,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-minutes-up,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-hours-up,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-minutes-up,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-hours-up,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-minutes-up,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-hours-up,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-minutes-up {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 4px;
    background: white;
    border: 1px solid #ddd;
    background: white
}

.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-hours-down,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-minutes-down,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-hours-down,
.calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-minutes-down,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-hours-down,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows .calentim-timepicker-minutes-down,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-hours-down,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows .calentim-timepicker-minutes-down {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 4px;
    background: white;
    border: 1px solid #ddd;
    background: white;
    border-top: none
}

.calentim-container .calentim-input .calentim-ranges,
.calentim-container-mobile .calentim-input .calentim-ranges {
    border-top: 1px solid #ddd;
    clear: both;
    padding: 10px;
    text-align: left
}

.calentim-container .calentim-input .calentim-ranges i,
.calentim-container-mobile .calentim-input .calentim-ranges i {
    float: left;
    color: #6a6e71;
    font-size: 14px;
    margin-right: 5px;
    margin-top: 1px
}

.calentim-container .calentim-input .calentim-ranges .calentim-range-header,
.calentim-container-mobile .calentim-input .calentim-ranges .calentim-range-header {
    font-size: 0.8em;
    color: #6a6e71;
    display: inline-block;
    margin-right: 5px;
    font-weight: bold
}

.calentim-container .calentim-input .calentim-ranges .calentim-range,
.calentim-container-mobile .calentim-input .calentim-ranges .calentim-range {
    display: inline-block;
    color: #1783FF;
    margin: 3px;
    font-size: 0.7em;
    cursor: pointer;
    font-weight: bold
}

.calentim-container .calentim-input .calentim-ranges .calentim-range.calentim-range-selected,
.calentim-container-mobile .calentim-input .calentim-ranges .calentim-range.calentim-range-selected {
    color: #f03a47
}

.calentim-container.calentim-hidden-calendar .calentim-input .calentim-timepickers {
    border-top: none
}

.calentim-container.calentim-hidden-calendar .calentim-box-arrow-top:after {
    border-bottom-color: #f8f8ff
}

.calentim-container .calentim-input .calentim-footer {
    text-align: right
}

.calentim-container .calentim-input .calentim-footer .calentim-apply {
    background: #1783FF;
    border: none;
    padding: 6px 18px;
    color: white;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    margin: 6px;
    border-radius: 3px
}

.calentim-container .calentim-input .calentim-footer .calentim-apply:disabled {
    background: #c9c9d1;
    color: gray
}

.calentim-container .calentim-input .calentim-footer .calentim-cancel {
    background: #c9c9d1;
    border: none;
    padding: 6px 18px;
    color: #6a6e71;
    margin: 6px;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    margin-right: 0px;
    border-radius: 3px
}

.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month:hover {
    background: #1783FF;
    cursor: pointer;
    color: white
}

.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-next:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year-prev:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-next:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year-prev:hover {
    background: #1783FF;
    cursor: pointer;
    color: white
}

.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev i:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next i:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-prev i:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title .calentim-next i:hover {
    text-shadow: 1px 2px 3px #eee
}

.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title span .calentim-month-switch:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-title span .calentim-year-switch:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title span .calentim-month-switch:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title span .calentim-year-switch:hover {
    color: #1783FF;
    cursor: hand;
    cursor: pointer
}

.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day:hover:not(.calentim-hovered):not(.calentim-selected),
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day:hover:not(.calentim-hovered):not(.calentim-selected) {
    background: gainsboro;
    border-radius: 3px
}

.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-next:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-calendars .calentim-calendar .calentim-prev:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-next:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-prev:hover {
    text-shadow: 1px 2px 3px #eee
}

.calentim-notouch .calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-am:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-am:hover {
    cursor: pointer;
    cursor: hand;
    background: #f8f8ff
}

.calentim-notouch .calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-pm:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-timepicker-ampm-pm:hover {
    cursor: pointer;
    cursor: hand;
    background: #f8f8ff
}

.calentim-notouch .calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-ampm-selected:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-ampm .calentim-ampm-selected:hover {
    background: #0666CD;
    color: white
}

.calentim-notouch .calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows>div:hover,
.calentim-notouch .calentim-container .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows>div:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows>div:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows>div:hover {
    background: whitesmoke;
    cursor: pointer;
    cursor: hand
}

.calentim-notouch .calentim-container .calentim-input .calentim-ranges .calentim-range:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-ranges .calentim-range:hover {
    text-decoration: underline
}

.calentim-notouch .calentim-container .calentim-input .calentim-ranges .calentim-range.calentim-range-selected:hover,
.calentim-notouch .calentim-container-mobile .calentim-input .calentim-ranges .calentim-range.calentim-range-selected:hover {
    text-decoration: none
}

.calentim-container-mobile.calentim-hidden-calendar .calentim-input .calentim-timepickers {
    max-width: 100%;
    border-bottom: none;
    border-top: none
}

.calentim-container-mobile.calentim-hidden-calendar .calentim-input .calentim-footer {
    width: 100%;
    background: white
}

.calentim-container-mobile .calentim-input {
    margin: 0;
    border: 0;
    border-radius: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    margin: 6px;
    border-radius: 6px;
    border: 1px solid #ddd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    overflow-y: hidden
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input {
        bottom: 0;
        overflow-y: hidden;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.calentim-container-mobile .calentim-input .calentim-header {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: nowrap;
    flex-flow: nowrap;
    background: #f8f8ff;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1em
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-header {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        border-left: 1px solid #ddd;
        font-size: .857em
    }
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-separator {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: lightsteelblue;
    margin-top: 2px
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end {
    margin: 0
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-end-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-start-day,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-day {
    font-size: 2.8em;
    margin: -10px;
    font-weight: 400
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-end-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-start-date,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-date {
    font-size: 1em;
    color: #424242;
    font-weight: 500
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-end-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-start-weekday,
.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-weekday {
    font-size: 0.8em;
    color: #424242;
    font-weight: 400;
    line-height: 10px
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-start .calentim-header-start-day {
    margin-right: 8px;
    margin-left: 0;
    float: left
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end {
    text-align: right
}

.calentim-container-mobile .calentim-input .calentim-header .calentim-header-end .calentim-header-end-day {
    float: right;
    margin-left: 8px;
    margin-right: 0
}

.calentim-container-mobile .calentim-input .calentim-calendars {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: scroll
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-calendars {
        width: 50%;
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0
    }
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector {
    border-bottom: 1px solid #ddd
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ms-month,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ms-month {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
    height: 25%
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-month-selector .calentim-ys-year,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-year-selector .calentim-ys-year {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20%;
    flex: 1 1 20%;
    height: 33.33%
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar {
    border-right: none;
    display: block
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar {
        border: none
    }
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar:first-child .calentim-title {
    border-top: none
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title {
    background: #f8f8ff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25em;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #ddd;
    color: #424242
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-title {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 8px
    }
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container {
    padding: 10px 4px
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled {
    min-height: calc((100% - 48px) / 8);
    font-size: 0.8572em;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-dayofweek span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-day span,
.calentim-container-mobile .calentim-input .calentim-calendars .calentim-calendar .calentim-days-container .calentim-disabled span {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.calentim-container-mobile .calentim-input .calentim-ranges {
    background: white;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 0px;
    padding: 4px;
    border: none;
    text-align: center
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-ranges {
        width: 50%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0px;
        flex: 1 1 0;
        border-left: 1px solid #ddd;
        overflow-y: scroll;
        min-height: 50px
    }
}

.calentim-container-mobile .calentim-input .calentim-ranges i {
    font-size: 20px;
    display: none
}

.calentim-container-mobile .calentim-input .calentim-ranges .calentim-range-header {
    font-size: 0.9em;
    display: none
}

.calentim-container-mobile .calentim-input .calentim-ranges .calentim-range {
    font-size: 0.85em;
    padding: 4px 8px;
    background: #0666CD;
    font-weight: 400;
    color: white;
    border-radius: 21px
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-ranges .calentim-range {
        border-radius: 6px;
        display: block;
        margin: 4px
    }
}

.calentim-container-mobile .calentim-input .calentim-filler {
    display: none
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-filler {
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-bottom: auto;
        border-left: 1px solid #ddd;
        min-height: 100px;
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }
}

.calentim-container-mobile .calentim-input .calentim-ranges+.calentim-filler {
    display: none
}

.calentim-container-mobile .calentim-input .calentim-footer {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: center;
    padding: 6px 6px 7px 6px;
    background: #f8f8ff;
    border-top: 1px solid #ddd
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-footer {
        width: 50%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-left: 1px solid #ddd;
        margin-top: auto
    }
}

.calentim-container-mobile .calentim-input .calentim-footer button {
    font-size: 15px;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 4px;
    font-weight: 400;
    outline: none;
    border: none;
    border-radius: 4px;
    width: calc((100% - 10px)/2)
}

.calentim-container-mobile .calentim-input .calentim-footer button.calentim-apply {
    background: #f03a47;
    color: white;
    margin-left: 7px;
    font-weight: 500;
    border: 1px solid #f03a47
}

.calentim-container-mobile .calentim-input .calentim-footer button.calentim-apply[disabled=disabled],
.calentim-container-mobile .calentim-input .calentim-footer button.calentim-apply:disabled {
    background: #c9c9d1;
    color: #A6AAB3;
    border: 1px solid #A6AAB3
}

.calentim-container-mobile .calentim-input .calentim-footer button.calentim-cancel {
    background: transparent;
    color: #f03a47;
    border: 1px solid #f03a47;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.calentim-container-mobile .calentim-input .calentim-timepickers {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background: ghostwhite;
    font-size: 1em;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-timepickers {
        border-top: none;
        border-bottom: 1px solid #ddd;
        border-left: 1px solid #ddd;
        font-size: 1.125em;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }
}

.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-hour-minute-seperator {
    margin-top: 0px;
    color: #6a6e71;
    font-size: 1.8em;
    font-weight: bold
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-hour-minute-seperator {
        margin-top: 0px;
        font-size: 1.4em
    }
}

.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours {
    font-size: 21px;
    border: 1px solid #ddd;
    border-radius: 3px
}

.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hours-wrapper .calentim-timepicker-hours .calentim-hour-selected {
    background: whitesmoke;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
    margin: 0 2px
}

.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes {
    font-size: 21px;
    border: 1px solid #ddd;
    border-radius: 3px
}

.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minutes-wrapper .calentim-timepicker-minutes .calentim-minute-selected {
    background: whitesmoke;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
    margin: 0 2px
}

.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-hour-arrows,
.calentim-container-mobile .calentim-input .calentim-timepickers .calentim-timepicker .calentim-timepicker-minute-arrows {
    display: none
}

.calentim-container-mobile.calentim-inline {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.calentim-container-mobile.calentim-inline .calentim-input {
    position: static;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.calentim-container-mobile.calentim-inline .calentim-input .calentim-footer {
    display: none !important
}

.calentim-container-mobile.calentim-inline .calentim-input.calentim-hidden-calendar .calentim-timepickers {
    max-width: 100%;
    border-top-width: 0 !important
}

@media screen and (orientation: landscape) {
    .calentim-container-mobile.calentim-inline .calentim-input {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .calentim-container-mobile.calentim-inline .calentim-input .calentim-calendars {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        width: calc(50% - 1px);
        border-left: none;
        border-right: 1px solid #ddd
    }

    .calentim-container-mobile.calentim-inline .calentim-input .calentim-header {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: calc(50% - 1px);
        border-left: none;
        border-right: 1px solid #ddd;
        border-bottom: none
    }

    .calentim-container-mobile.calentim-inline .calentim-input .calentim-timepickers {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 50%;
        border-left: none
    }

    .calentim-container-mobile.calentim-inline .calentim-input .calentim-timepickers .calentim-timepicker-ampm {
        width: 7vmin
    }

    .calentim-container-mobile.calentim-inline .calentim-input .calentim-ranges {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 50%;
        border-left: none
    }
}

.calentim-open {
    overflow: hidden
}

.calentim-overlay {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999998
}

.calentim-no-anim {
    -webkit-transition: all 0s ease !important;
    -o-transition: all 0s ease !important;
    transition: all 0s ease !important
}

.calentim-popup {
    position: absolute;
    display: none;
    top: 0;
    left: 0
}

.calentim-box-arrow-top {
    width: 22px;
    height: 22px;
    z-index: 12;
    margin-left: 0;
    position: absolute;
    top: 0px
}

.calentim-box-arrow-top:after,
.calentim-box-arrow-top:before {
    bottom: calc(100% - 6px);
    left: 27px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.calentim-box-arrow-top:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px
}

.calentim-box-arrow-top:before {
    border-color: rgba(184, 184, 184, 0);
    border-bottom-color: #ddd;
    border-width: 11px;
    margin-left: -11px
}

.calentim-box-arrow-bottom {
    width: 22px;
    height: 22px;
    z-index: 12;
    margin-left: 0;
    position: absolute;
    bottom: 0px
}

.calentim-box-arrow-bottom:after,
.calentim-box-arrow-bottom:before {
    bottom: -15px;
    left: 27px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.calentim-box-arrow-bottom:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 11px;
    margin-left: -11px
}

.calentim-box-arrow-bottom:before {
    border-color: rgba(184, 184, 184, 0);
    border-top-color: #ddd;
    border-width: 10px;
    margin-left: -10px
}

.calentim-box-arrow-left {
    width: 22px;
    height: 22px;
    z-index: 12;
    margin-top: -11px;
    position: absolute;
    left: 0px
}

.calentim-box-arrow-left:after,
.calentim-box-arrow-left:before {
    left: -15px;
    top: 27px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.calentim-box-arrow-left:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 11px;
    margin-right: -11px
}

.calentim-box-arrow-left:before {
    border-color: rgba(184, 184, 184, 0);
    border-right-color: #ddd;
    border-width: 10px;
    margin-right: -10px
}

.calentim-box-arrow-right {
    width: 22px;
    height: 22px;
    z-index: 12;
    margin-top: -11px;
    position: absolute;
    right: 0px
}

.calentim-box-arrow-right:after,
.calentim-box-arrow-right:before {
    right: -15px;
    top: 27px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.calentim-box-arrow-right:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #ffffff;
    border-width: 11px;
    margin-left: -11px
}

.calentim-box-arrow-right:before {
    border-color: rgba(184, 184, 184, 0);
    border-left-color: #ddd;
    border-width: 10px;
    margin-left: -10px
}

.calentim-no-scroll::-webkit-scrollbar {
    display: none
}

.calentim-no-scroll::-moz-scrollbar {
    display: none
}

.calentim-no-scroll::-o-scrollbar {
    display: none
}

.calentim-no-scroll::-google-ms-scrollbar {
    display: none
}

.calentim-no-scroll::-khtml-scrollbar {
    display: none
}