.kyc-confirmation-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  }
  
  .kyc-confirmation-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 3rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
  }
  
  .confirmation-icon {
    font-size: 5rem;
    color: #28a745;
  }
  
  h1 {
    font-weight: 700;
    color: #333;
  }
  
  h2 {
    font-weight: 600;
    color: #555;
  }
  
  .lead {
    font-size: 1.2rem;
    color: #666;
  }
  
  .login-button {
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  
  .login-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 576px) {
    .kyc-confirmation-card {
      padding: 2rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .lead {
      font-size: 1rem;
    }
  
    .login-button {
      padding: 0.6rem 1.5rem;
      font-size: 1rem;
    }
  }