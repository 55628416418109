.stepper-form {
    max-width: 100%;
    /* margin: 2rem auto; */
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .stepper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    overflow-x: auto;
    padding-bottom: 1rem;
  }
  
  .step {
    display: flex;
    align-items: center;
    color: #6c757d;
    font-size: 0.9rem;
    white-space: nowrap;
    position: relative;
    flex: 1;
    text-align: center;
    padding: 0 10px;
  }
  .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -50%;
    width: 100%;
    height: 2px;
    background-color: #e9ecef;
    z-index: 1;
  }
  .step-icon,
.step-number {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #e9ecef;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  font-size: 0.8rem;
  position: relative;
  z-index: 2;
}
  
  .step.active {
    color: #28a745;
    background: none !important;
    
  }
  
  .step.active .step-icon,
  .step.active .step-number {
    background-color: #28a745;
    color: white;
  }
  .step-text {
  
  }
  .form-label {
    font-weight: 500;
    color: #495057;
  }
  
  .form-control, .form-select {
    border-radius: 0;
    border: 1px solid #ced4da;
  }
  
  .btn-success {
    background-color: #28a745;
    border-color: #28a745;
    padding: 0.5rem 2rem;
  }
  .step active{
    background: none !important;
  }
  .input-group-text {
    background-color: #f8f9fa;
    border-right: none;
}

.inpuformloan {
    border-left: none;
    border: 2px solid #ddd;
    /* border-radius: 4px; */
    font-family: 'Rubik', sans-serif;
    outline: 0;
    transition: .2s;

}

.inpuformloan:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.input-group:focus-within .input-group-text {
    border-color: #80bdff;
}

.input-group:focus-within .inpuformloan {
    border-color: #80bdff;
}
.setpcontent{

}

  @media (max-width: 768px) {
    .stepper {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    
    .step {
      margin-right: 1rem;
    }
    .input-group-text {
        font-size: 0.9rem;
    }
    
    .inpuformloan {
        font-size: 0.9rem;
    }
    .step-text {
        display: none;
      }
  }
  @media (max-width: 767px) {
    .stepper {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    
    .step {
      margin-right: 1rem;
    }
  }
  /* LoanNavbar.css */
.loan-navbar {
  background-color: #1a237e !important; /* Deep blue background */
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  margin-left: 0.1rem ;
}

.loan-navbar .brand {
  color: #ffffff !important;
  font-weight: bold;
  font-size: 1.5rem;
}

.loan-navbar .nav-link {
  color: #ffffff !important;
  font-weight: 500;
  transition: color 0.3s ease;
}

.loan-navbar .nav-link:hover {
  color: #ffd54f !important; /* Light yellow on hover */
}

.loan-navbar .custom-dropdown .dropdown-toggle {
  color: #ffffff !important;
  font-weight: 500;
}

.loan-navbar .custom-dropdown .dropdown-menu {
  background-color: #3949ab; /* Slightly lighter blue for dropdown */
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,.1);
}

.loan-navbar .custom-dropdown .dropdown-item {
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.loan-navbar .custom-dropdown .dropdown-item:hover {
  background-color: #283593; /* Darker blue on hover */
  color: #ffd54f; /* Light yellow text on hover */
}

@media (max-width: 991px) {
  .loan-navbar .navbar-nav {
    background-color: #1a237e;
    padding: 10px;
  }
  
  .loan-navbar .custom-dropdown .dropdown-menu {
    background-color: #283593;
  }
}