.tabs {
    /* font-family: Arial, sans-serif; */
    /* margin: 20px; */
    margin-top: 10px;
}

.tab-buttons {
    display: flex;
    /* border-bottom: 2px solid #ccc; */
    background: #2e3191;
}

.tab-buttons button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    color: #ffff;
}

.tab-buttons button.active {
    /* border-bottom: 2px solid #000; */
    font-weight: bold;
    background-color: #f0f0f0;
    color: #000;
}

.tab-buttons button:hover {
    background-color: #f0f0f0;
}

.tab-content {
    /* padding: 20px; */
    /* border: 1px solid #ccc; */
    border-top: none;
}