.wrapper{
    max-width: 1100px;
    margin: 20px auto;
}
.wrapper .filter_wrapper{
    width: 100%;
    background-color: #FFF;
    border: 1px solid #d4d5d5;
    border-radius: 4px;
    border-bottom: 3px solid #cacbcb;
    margin-bottom: 15px;
    padding: 30px;
}
.wrapper .filter_wrapper h1{
    text-align: center;
    padding: 15px 0;
    margin-bottom: 20px;
    font-weight: 700;
    position: relative;
}
.wrapper .filter_wrapper h1::before{
    position: absolute;
    content: '';
    width: 120px;
    left: 50%;
    bottom: 0;
    margin-left: -60px;
    height: 3px;
    background: #0076ac;
}
.subscribe{
    text-align: left;
}
.subscribe p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #868484;
}
.table{
    border: 1px solid #ccc;
}
.table .cols{
    display: flex;
}
.table .cols li{
    padding: 10px;
    width: 30%;
    font-size: 17px;
    border-left: 1px solid #ccc;
}
.table .cols li.title{
    font-weight: 700;
    background: #e0e0e0;
    border-bottom: 1px solid #ccc;
}
.table .cols li:first-child{
    border-left: none;
    width: 10%;
}
.plans{
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}
.s_plan{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    margin-top: 50px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
.s_plan > div{
    display: flex;
    gap: 5px;
    align-items: center;
}
.s_plan label{
    font-size: 15px;
    font-weight: 700;
}
.s_plan input{
    width: 17px;
    height: 17px;
    border: #868484;
}
.terms{
    margin-top: 10px;
}
.terms h4{
    font-size: 23px;
}
.terms ul{
    padding: 10px 30px;
}
.terms ul li{
    list-style: decimal;
    margin: 10px;
    font-size: 15px;
    color: #303030;
}
.selector_wrap{
    width: 600px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
}
.selector_wrap .selector{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 5px 10px; 
}
.selector_wrap .selector span{
    color: #acaaaa;
    text-transform: uppercase;
}
.month{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0px 0px 8px 8px;
    text-align: left;
    padding: 10px;
    background: #fff;
    position: absolute;
    height: auto;
    overflow-y: visible;
    text-transform: capitalize;
    z-index: 99;
}
.month ul li{
    font-size: 15px;
    color: #797979;
    margin-bottom: 10px;
    padding: 5px;
}
.month ul li:hover{
    background: #0076ac;
    color: #fff;
}
.block{
    display: block;
}
.hidden{
    display: none;
}
.ref{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
.agree{
    display: flex;
    gap: 6px;
    justify-content: center;
    text-align: center;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc ;
}
.agree button{
    width: 140px;
    height: 40px;
    line-height: 34px;
    background: #00c700;
    color: #fff;
    font-size: 15px;
}
.agree button:hover{
    background: #005700;
}
.ref-text{
    width: 300px;
    height: 34px;
    border: 1px solid #cccc;
    padding:5px 10px;
    box-sizing: border-box;
    border-radius: 8px ;
}
.ref-text input{
    width: 100%;
    border: none;
}
.ref-text input:focus{
    outline: none;
}

