  .retailer-dashboard {
    display: flex;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .retailer-dashboard__sidebar {
    width: 240px;
    background-color: #001529;
    padding: 20px 0;
    transition: all 0.3s ease;
  }
  
  .retailer-dashboard__tab {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .retailer-dashboard__tab:hover {
    background-color: #1890ff;
  }
  
  .retailer-dashboard__tab.active {
    background-color: #1890ff;
  }
  
  .retailer-dashboard__tab-icon {
    margin-right: 12px;
    font-size: 20px;
  }
  
  .retailer-dashboard__tab-label {
    font-size: 14px;
    font-weight: 500;
  }
  
  .retailer-dashboard__content {
    flex: 1;
    padding: 24px;
    overflow-y: auto;
  }
  
  .retailer-dashboard__title {
    margin-bottom: 24px;
    color: #001529;
    font-weight: 600;
  }
  
  .retailer-dashboard__overview,
  .retailer-dashboard__services,
  .retailer-dashboard__transactions {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .retailer-dashboard__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  @media (max-width: 768px) {
    .retailer-dashboard {
      flex-direction: column;
    }
  
    .retailer-dashboard__sidebar {
      width: 100%;
      padding: 10px 0;
    }
  
    .retailer-dashboard__tab {
      padding: 8px 16px;
    }
  
    .retailer-dashboard__content {
      padding: 16px;
    }
  }
  @media (min-width: 1200px) {
    .css-5c1adp-MuiContainer-root {
        max-width: 99% !important;
    }
}