.box {
    flex: 1;
    max-width: 100%;
    background-color: #683093;
    padding: 20px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    color: #fff;
}

.box:hover {
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.icon svg {
    width: 50px;
    height: 50px;
}

.name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.box a {
    text-decoration: none;
    color: #fff;
}

.info-set {
    color: blue;
    font-weight: 600;
}