.MerchantLoginHeader {
    padding: 15px 0;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.image-log img {
    max-height: 50px;
    width: auto;
}

.helpline-link {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.3s ease;
}

.btn.login-area {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.btn.login-area:hover {
    background-color: #0056b3;
}

/* Option 1: Animated sliding text */
.helpline-animated {
    overflow: hidden;
    white-space: nowrap;
}

.sliding-text {
    display: inline-block;
    padding-left: 100%;
    animation: slide 15s linear infinite;
}

@keyframes slide {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

/* Option 2: Multiple contact options */
.helpline-multi {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.helpline-multi .helpline-link {
    margin: 0 10px;
}

/* Option 3: Tooltip with operating hours */
.helpline-tooltip {
    position: relative;
}

.tooltip-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1000;
}

/* Option 4: Accent color and hover effect */
.helpline-accent .helpline-link {
    background-color: #f0f8ff;
    padding: 10px 15px;
    border-radius: 25px;
    transition: all 0.3s ease;
}

.helpline-accent .helpline-link:hover {
    background-color: #e6f3ff;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.icon-circle {
    background-color: #007bff;
    color: white;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .helpline-link, .sliding-text {
        font-size: 14px;
    }

    .btn.login-area {
        padding: 6px 12px;
        font-size: 12px;
    }

    .buttonArea {
        margin-top: 10px;
    }

    .helpline-multi {
        flex-direction: column;
        align-items: center;
    }

    .helpline-multi .helpline-link {
        margin: 5px 0;
    }
}

@media (max-width: 575px) {
    .image-log img {
        max-height: 40px;
    }

    .helpline-accent .helpline-link {
        padding: 8px 12px;
    }

    .icon-circle {
        width: 30px;
        height: 30px;
    }

    .buttonArea {
        justify-content: center !important;
    }
}