.billing-address-container {
    margin-top: 20px;
  }
  
  .billing-address-main-card {
    margin: 0 auto;
  }
  
  .billing-address-card {
    height: 100%;
  }
  
  .billing-address-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .billing-address-card-title {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
  }
  
  .billing-address-card-actions {
    display: flex;
  }
  
  .billing-address-card-content {
    font-size: 14px;
  }
  
  .billing-address-card-field {
    margin-bottom: 5px;
  }
  
  .billing-address-card-label {
    font-weight: 600;
    margin-right: 5px;
  }
  
  .billing-address-empty-card {
    text-align: center;
  }
  
  .billing-address-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
  }