.dashboard-tabs .nav-tabs {
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 20px;
  }
  
  .dashboard-tabs .nav-tabs .nav-link {
    border: none;
    color: #555;
    font-weight: 600;
    padding: 12px 20px;
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  
  .dashboard-tabs .nav-tabs .nav-link:hover {
    border: none;
    color: #3498db;
  }
  
  .dashboard-tabs .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 3px solid #3498db;
    color: #3498db;
  }
  
  .dashboard-card {
    border-radius: 12px;
    padding: 25px;
    color: white;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .icon-wrapper {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  
  .card-icon {
    font-size: 28px;
  }
  
  .card-content {
    flex-grow: 1;
  }
  
  .card-count {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .card-title {
    font-size: 16px;
    margin-bottom: 0;
    opacity: 0.9;
  }