.notification-sidebar {
    max-width: 400px;
    width: 100%;
}

.notification-sidebar .offcanvas-header {
    background-color: #f0f2f5;
    border-bottom: 1px solid #e8e8e8;
}

.notification-sidebar .offcanvas-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notification-item {
    transition: background-color 0.3s;
    padding: 12px;
    border-radius: 8px;
}

.notification-item:hover {
    background-color: #f0f2f5;
}

.notification-time {
    display: block;
    font-size: 0.8em;
    margin-top: 5px;
}

@media (max-width: 576px) {
    .notification-sidebar {
        max-width: 100%;
    }
}