.user-type-selector {
    padding: 2rem 0;
  }
  
  .selector-underline {
    width: 50px;
    height: 4px;
    background-color: #007bff;
    margin: 0 auto 2rem;
  }
  
  .user-type-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .user-type-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .user-type-card.selected {
    border: 2px solid #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }
  
  .icon-wrapper {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #007bff;
  }
  
  .user-type-card h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  