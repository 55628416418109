.card-header-card {
    background-color: #2e319152;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.operator {
    background: skyblue;
    padding: 5px;
    text-align: center;
    margin: 0px;
    border-radius: 5px;
    color: #ffff;

}

.parent-set h5 {
    margin-bottom: 0px;
}

.nameAndDetails {
    display: flex;

    align-items: center;
}

.avtar-set {
    margin-right: 15px;
}

.avtar-set svg {
    font-size: 45px !important;
}

.parent-set {
    margin-right: 15px;
}

.dateandtime p {
    margin: 0px;
    color: #ffff;
    font-weight: 600;
}

.chat-first {
    border: 1px solid #e4e1e1;
}

.msg-sec {
    padding: 10px;
}

.card-header-card-2 {
    /* margin-top: 20px; */
    background-color: rgb(245 243 243);
    color: #000;
}

.dateandtime-2 p {
    color: #000;
}

.parent-set .operator-2 {
    background-color: greenyellow;
}