.coming-soon-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  position: relative;
  overflow: hidden;
}

.contentcomming {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.contentcomming.show {
  opacity: 1;
  /* transform: scale(1); */
}

#celebration-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media (max-width: 768px) {
 .celebrationh1 {
    font-size: 2rem;
  }
  .celebrationh2 {
    font-size: 1.5rem;
  }
}
